import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Header from '../components/Header';
import CustomerDetails from '../components/CustomerDetails';
import Receipt from '../components/Receipt';
import OrderActionButtons from '../components/OrderActionButtons';
import VendorShoppinList from '../components/VendorShoppinList';
import OrderDetailSection from '../components/OrderDetailSection';
import { useParams, useLocation } from 'react-router-dom';

interface ParamTypes {
  orderID: string;
}
interface OrdersProps {}
interface IState {
  shopper: string;
  isCompleted: string;
  customerType: string;
  orderTypeID: string;
  shopName: string;
  startTime: string;
  shoppingCompleted: string;
  deliveryStarted: string;
  deliveryCompleted: string;
  agentID: string;
}

const Orders: React.FC<OrdersProps> = (props) => {
  const location = useLocation();
  let { orderID } = useParams<ParamTypes>();
  const shopper = (location.state as IState).shopper;
  const isCompleted = (location.state as IState).isCompleted;
  const customerType = (location.state as IState).customerType;
  const orderTypeID = (location.state as IState).orderTypeID;
  const shopName = (location.state as IState)?.shopName;
  const startTime = (location.state as IState)?.startTime;
  const shoppingCompleted = (location.state as IState)?.shoppingCompleted;
  const deliveryStarted = (location.state as IState)?.deliveryStarted;
  const deliveryCompleted = (location.state as IState)?.deliveryCompleted;
  const agentID = (location.state as IState)?.agentID;

  return (
    <React.Fragment>
      <Header {...props} />
      <Container style={{ paddingTop: '20px', height: '100%' }} maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <OrderActionButtons shopper={shopper} orderID={orderID} />
          </Grid>
          <Grid container direction="column" style={{ height: '100%' }} item xs={12} sm={4}>
            <CustomerDetails customerType={customerType} orderID={orderID} />
            <OrderDetailSection
              orderTypeID={orderTypeID}
              isCompleted={isCompleted}
              orderID={orderID}
              shopName={shopName}
              startTime={startTime}
              shoppingCompleted={shoppingCompleted}
              deliveryStarted={deliveryStarted}
              deliveryCompleted={deliveryCompleted}
              agentID={agentID}
            />
          </Grid>
          <Grid item xs={12} sm={7}>
            {isCompleted ? (
              <Receipt orderTypeID={orderTypeID} isCompleted={isCompleted} orderID={orderID} />
            ) : (
              <VendorShoppinList />
            )}
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Orders;
