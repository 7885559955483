import React, { FunctionComponent, ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { getStores } from '../api';
import { useQuery } from 'react-query';
import * as store from 'store';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 150,
    marginTop: theme.spacing(2),
  },
  label: {
    backgroundColor: 'white',
  },
  menuPaper: {
    maxHeight: 200,
  },
}));

interface MallSelectProps {
  AppState: any;
}

interface Store {
  name: string;
}
const MallFilter: FunctionComponent<MallSelectProps> = (props) => {
  const accessToken = store.get('spotlightAccessToken');
  const { AppState } = props;
  const [state, dispatch] = AppState();
  const { selectedStores } = state;

  let stores = [];
  const { data } = useQuery<any, Error>(['stores', accessToken], () => getStores({ accessToken }), {
    enabled: !!accessToken,
  });
  if (data?.data) {
    stores = data?.data.details;
  }
  stores.sort((a: Store, b: Store) => (a.name > b.name ? 1 : -1));

  const handleStatusChange = (event: ChangeEvent<{ value: unknown }>): void => {
    dispatch({ type: 'SET_SELECTED_STORES', payload: event.target.value as string });
  };
  const classes = useStyles();

  return (
    <div>
      <FormControl style={{ maxWidth: 120 }} className={classes.formControl} variant="outlined">
        <InputLabel id="mallfilter-label" className={classes.label}>
          Mall
        </InputLabel>
        <Select
          labelId="store-select-label"
          id="s-select"
          value={selectedStores}
          multiple
          input={<OutlinedInput />}
          onChange={handleStatusChange}
          renderValue={(selectedStores) => (selectedStores as string[]).join(', ')}
          MenuProps={{
            classes: { paper: classes.menuPaper },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          {stores.map((store: Store) => (
            <MenuItem key={store.name} value={store.name}>
              <Checkbox checked={selectedStores.indexOf(store.name) > -1} />
              <ListItemText primary={store.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default MallFilter;
