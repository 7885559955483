import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import VendorFilter from './VendorFilter';
import DateFilter from './DateFilter';
import StatusSelect from './StatusSelect';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import MallFilter from '../components/MallFilter';

interface FilterProps {
  setStartDate: (date: MaterialUiPickersDate) => void;
  setEndDate: (date: MaterialUiPickersDate) => void;
  startDate: string;
  endDate: string;
  AppState: any;
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

const FilterResults: FunctionComponent<FilterProps> = (props) => {
  const { AppState } = props;
  const classes = useStyles();
  const { setStartDate, setEndDate, startDate, endDate } = props;
  return (
    <div className={classes.root}>
      <VendorFilter AppState={AppState} />
      <MallFilter AppState={AppState} />
      <DateFilter setStartDate={setStartDate} setEndDate={setEndDate} startDate={startDate} endDate={endDate} />
      <StatusSelect AppState={AppState} />
    </div>
  );
};

export default FilterResults;
