import { axiosInstance, getUrlWithQueryParams } from '../utils/utils';
interface GetOrdersProps {
  startDate: string;
  endDate: string;
  orderID?: string | null;
  accessToken: string | null;
}
interface Token {
  accessToken: string | null;
}

interface Order extends Token {
  orderID: string;
}

interface Reset extends Order {
  resetShopper: number;
}

interface Refund extends Order {
  amount: string;
  reason: string;
}
interface Reschedule extends Order {
  deliveryDate: string;
  deliveryFrom: string;
}
interface ChangeStore extends Order {
  storeName: string;
}
interface Comment extends Order {
  comment: string;
}
interface GetReceiptProps extends Order {
  email: string;
}

export async function getOrders(props: GetOrdersProps) {
  const { startDate, endDate, orderID, accessToken } = props;
  const url = getUrlWithQueryParams(process.env.REACT_APP_SLS_API_ENDPOINT + '/v1/spotlight/orders', {
    startDate: orderID ? '' : startDate,
    endDate: orderID ? '' : endDate,
    orderIDOverride: orderID ?? ''
  });
  const response = await axiosInstance.get(url.toString(), {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
}

export async function getMalls(props: Token) {
  const { accessToken } = props;
  const url = getUrlWithQueryParams(process.env.REACT_APP_SLS_API_ENDPOINT + '/v1/spotlight/orders/getStores', {});
  const response = await axiosInstance.get(url.toString(), {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
}
export async function getCustomerDetails(props: Order) {
  const { accessToken, orderID } = props;
  const url = getUrlWithQueryParams(
    process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderID}/customerDetails`,
    {}
  );
  const response = await axiosInstance.get(url.toString(), {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
}

export async function getShoppingList(props: Order) {
  const { accessToken, orderID } = props;
  const url = getUrlWithQueryParams(
    process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderID}/shoppingList`,
    {}
  );
  const response = await axiosInstance.get(url.toString(), {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
}
export async function assignOrder(props: Order) {
  const { accessToken, orderID } = props;
  const url = process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderID}/assignOrder`;

  const response = await axiosInstance.post(
    url,
    {},
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );

  return response;
}

export async function holdOrder(props: Order) {
  const { accessToken, orderID } = props;
  const url = process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderID}/holdOrder`;

  const response = await axiosInstance.post(
    url,
    {},
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );

  return response;
}
export async function resetOrder(props: Reset) {
  const { accessToken, orderID, resetShopper } = props;
  const url =
    process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderID}/reset?resetShopper=${resetShopper}`;

  const response = await axiosInstance.put(
    url,
    {},
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );

  return response;
}
export async function postToPicup(props: Order) {
  const { accessToken, orderID } = props;
  const url = process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderID}/requestPickUp`;

  const response = await axiosInstance.post(
    url,
    {},
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );

  return response;
}

export async function markCalled(props: Order) {
  const { accessToken, orderID } = props;
  const url = process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderID}/markCalled`;

  const response = await axiosInstance.post(
    url,
    {},
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );

  return response;
}
export async function makeRefund(props: Refund) {
  const { accessToken, orderID, reason, amount } = props;
  const url = process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderID}/refund`;

  const response = await axiosInstance.post(
    url,
    { reason, amount },
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );

  return response;
}
export async function rescheduleOrder(props: Reschedule) {
  const { accessToken, orderID, deliveryDate, deliveryFrom } = props;
  const url = process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderID}/reschedule`;

  const response = await axiosInstance.put(
    url,
    { deliveryDate, deliveryFrom },
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );

  return response;
}

export async function getTransactions(props: Order) {
  const { accessToken, orderID } = props;
  const url = getUrlWithQueryParams(
    process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderID}/transactions`,
    {}
  );
  const response = await axiosInstance.get(url.toString(), {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
}
export async function getStores(props: Token) {
  const { accessToken } = props;
  const url = getUrlWithQueryParams(process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/getStores`, {});
  const response = await axiosInstance.get(url.toString(), {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
}
export async function getComments(props: Order) {
  const { accessToken, orderID } = props;
  const url = getUrlWithQueryParams(
    process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderID}/getComment`,
    {}
  );
  const response = await axiosInstance.get(url.toString(), {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
}

export async function changeStore(props: ChangeStore) {
  const { accessToken, orderID, storeName } = props;
  const url = process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderID}/changeStore`;

  const response = await axiosInstance.post(
    url,
    { storeName },
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );

  return response;
}

export async function makeComment(props: Comment) {
  const { accessToken, orderID, comment } = props;
  const url = process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderID}/addComment`;

  const response = await axiosInstance.post(
    url,
    { comment },
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );

  return response;
}

export async function getReceiptData(props: GetReceiptProps) {
  const { email, orderID, accessToken } = props;
  const url = getUrlWithQueryParams(
    process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderID}/fulfilledOrder`,
    {
      email,
    }
  );
  const response = await axiosInstance.get(url.toString(), {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
}

export async function getChatAuth(props: Token) {
  const { accessToken } = props;
  const url = getUrlWithQueryParams(process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/chat/staff/authenticate`, {});
  const response = await axiosInstance.get(url.toString(), {
    headers: {
      Authorization: accessToken,
    },
  });

  if (response.status === 200) {
    return { chatAuth: response.data.details };
  } else {
    return { chatAuth: null };
  }
}
