import React, { FunctionComponent, Fragment } from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { useQuery } from 'react-query';
import { getShoppingList, getReceiptData, getCustomerDetails } from '../api';
import Button from '@material-ui/core/Button';
import { parseImage, mapProductFulfillment } from '../utils/utils';
import * as store from 'store';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Invoice from './Invoice';
import FullScreenDialog from './FullScreenDialog';

interface ReceiptProps {
  orderID: string;
  isCompleted: string;
  orderTypeID: string;
}

declare interface Product {
  productID: number;
  name: string;
  price: number;
  status: number;
  imageURL: string;
  orderQuantity: number;
  suppliedQuantity: number;
  substitutionName: string;
  substitutionID: number;
  substitutionQuantity: number;
  substitutionPrice: number;
  substitutionImageURL?: string;
  vendor: string;
  vendorID?: number;
  note?: string;
  total?: number;
}
interface Vendor {
  vendor: string;
  products: Product[];
  cost: string;
}

interface ProductListProps {
  products: Product[];
  substitute?: boolean;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      height: '65vh',
      borderRadius: theme.spacing(2),
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    avatar: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
    listText: {
      margin: theme.spacing(3),
      marginTop: theme.spacing(5),
    },
    arrowForward: {
      marginTop: theme.spacing(4),
      marginRight: theme.spacing(5),
      fontSize: theme.spacing(3),
      textAlign: 'right',
      lineHeight: theme.spacing(3),
    },
    refunded: {
      color: 'red',
    },
    substitued: {
      color: '#f1c40f',
    },
    container: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(8),
    },
    listItemText: {
      fontSize: theme.spacing(3),
    },
    orderUnavailable: {
      margin: 'auto',
      width: '80%',
      padding: '10px',
      textAlign: 'center',
    },
    substitute: {
      fontSize: theme.spacing(1.5),
    },
    receiptSubheader: {
      display: 'inline-block',
    },
    printButton: {
      float: 'right',
      margin: theme.spacing(1),
    },
  })
);
const TableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell);

const ProductList: FunctionComponent<ProductListProps> = (props) => {
  const { products, substitute } = props;
  const classes = useStyles();

  return (
    <List>
      <ListItem alignItems="flex-start">
        <TableContainer className={classes.container}>
          <Table aria-label="products table">
            <TableHead>
              <TableRow>
                <TableCell>Product</TableCell>
                <TableCell align="right">Qty</TableCell>
                <TableCell align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.length > 0 &&
                products.map((product) => (
                  <TableRow>
                    {substitute ? (
                      <TableCell>
                        {product.substitutionName} for <br></br>
                        <span className={classes.substitute}> {product.name}</span>
                      </TableCell>
                    ) : (
                      <TableCell>{product.name}</TableCell>
                    )}
                    <TableCell align="right">{product.orderQuantity}</TableCell>
                    <TableCell align="right">R {product.price}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ListItem>
    </List>
  );
};

const Receipt: FunctionComponent<ReceiptProps> = (props) => {
  const { orderID, isCompleted, orderTypeID } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  let vendors = [];
  let customerData = { customerEmail: '' };
  const accessToken = store.get('spotlightAccessToken');
  const { data } = useQuery(['receiptShopping', accessToken, orderID], () => getShoppingList({ accessToken, orderID }));

  if (data?.data) {
    vendors = data?.data.details;
  }

  const { data: customerDetails } = useQuery(['customerDetails', accessToken, orderID], () =>
    getCustomerDetails({ accessToken, orderID })
  );

  if (customerDetails?.data) {
    customerData = customerDetails?.data.details[0];
  }

  const { data: PrintData } = useQuery(
    ['printData', accessToken, orderID],
    () => getReceiptData({ accessToken, orderID, email: customerData.customerEmail }),
    {
      enabled: !!(vendors.length && customerData.customerEmail),
    }
  );

  const handleClick = () => {
    if (PrintData?.data.details) {
      setOpen(!open);
    }
  };
  return (
    <Fragment>
      <Paper style={{ maxHeight: '100%', overflow: 'auto', borderRadius: '20px' }}>
        <List
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              <Typography className={classes.receiptSubheader}>Receipt</Typography>
              {Number(isCompleted) === 1 && Number(orderTypeID) === 1 && (
                <Button
                  size="small"
                  variant="outlined"
                  className={classes.printButton}
                  startIcon={<ReceiptIcon color="primary" />}
                  onClick={handleClick}
                >
                  Print
                </Button>
              )}
            </ListSubheader>
          }
          className={classes.root}
        >
          {Number(isCompleted) === 1 ? (
            <Fragment>
              {vendors.length > 0 &&
                vendors.map((vendor: Vendor) => {
                  let imageUrl = '';
                  if (vendor.products.length > 0) {
                    imageUrl = parseImage(vendor.vendor);
                  }
                  let products: Product[] = vendor?.products;
                  const groupedProducts = mapProductFulfillment(products, vendor.vendor);

                  return (
                    <Fragment>
                      <ListItem alignItems="flex-start">
                        <Fragment>
                          <ListItemAvatar>
                            <Avatar className={classes.avatar} alt="Remy Sharp" src={`${imageUrl}`} />
                          </ListItemAvatar>
                          <ListItemText
                            className={classes.listText}
                            primary={vendor.vendor}
                            secondary={`${vendor.products.length} items`}
                          />
                        </Fragment>
                        <ListItemText
                          classes={{ primary: classes.listItemText }}
                          primary={`R ${Number(vendor.cost).toFixed(2)}`}
                          className={classes.arrowForward}
                        />
                      </ListItem>
                      {groupedProducts.foundItems.length > 0 && (
                        <Fragment>
                          <ListSubheader disableSticky color="primary">
                            Found Items
                          </ListSubheader>
                          <Divider />
                          <ProductList products={groupedProducts.foundItems} />
                        </Fragment>
                      )}
                      {groupedProducts.substitutedItems.length > 0 && (
                        <Fragment>
                          <ListSubheader classes={{ root: classes.substitued }} disableSticky color="primary">
                            Substituted Items
                          </ListSubheader>
                          <Divider />
                          <ProductList substitute={true} products={groupedProducts.substitutedItems} />
                        </Fragment>
                      )}
                      {groupedProducts.outOfStockItems.length > 0 && (
                        <Fragment>
                          <ListSubheader classes={{ root: classes.refunded }} disableSticky color="primary">
                            Refunded
                          </ListSubheader>
                          <Divider />
                          <ProductList products={groupedProducts.outOfStockItems} />
                        </Fragment>
                      )}
                    </Fragment>
                  );
                })}
            </Fragment>
          ) : (
            <Fragment>
              <Typography className={classes.orderUnavailable} variant="h5" gutterBottom>
                Receipt will be available when the order is completed
              </Typography>
            </Fragment>
          )}
        </List>
      </Paper>
      {open && (
        <FullScreenDialog closeModal={handleClick} title="Invoice" openModal={open}>
          <Invoice customerData={customerData} data={PrintData} />
        </FullScreenDialog>
      )}
    </Fragment>
  );
};

export default Receipt;
