import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

interface orderType {
  orderID: string;
  shopper: string;
  isCompleted: string;
  customerType: string;
  orderTypeID: string;
  shopName: string;
  startTime: string;
  shoppingCompleted: string;
  deliveryStarted: string;
  deliveryCompleted: string;
  agentID: string;
}

// custom channel preview component
class MyChannelPreview extends React.Component<ElementType<ChannelPreviewUIComponentProps>, any> {
  handleClick = (order: orderType) => {
    if (order.orderID !== undefined && order.orderID !== null && this.props.history) {
      this.props.history.push(`/orders/${order.orderID}`, {
        shopper: order.shopper,
        isCompleted: order.isCompleted,
        customerType: order.customerType,
        orderTypeID: order.orderTypeID,
        shopName: order.shopName,
        startTime: order.startTime,
        shoppingCompleted: order.shoppingCompleted,
        deliveryStarted: order.deliveryStarted,
        deliveryCompleted: order.deliveryCompleted,
        agentID: order.agentID,
      });
    }
  };

  dateObject = (date: any) => {
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = date.getFullYear();

    return `${mm}-${dd}-${yyyy}`;
  };

  timeObject = (date: any) => {
    let minutes = String(date.getMinutes()).padStart(2, '0');
    let seconds = String(date.getSeconds() + 1).padStart(2, '0'); //January is 0!

    return `${minutes}:${seconds}`;
  };

  render() {
    console.log('preview', this.props);
    const { setActiveChannel, channel, orders, setChannel, latestMessage } = this.props;
    // console.log('preview', latestMessage);

    const channelID = channel.id.split('-');
    const orderID = channelID[2];
    const newDate = new Date();
    const channelcreated = channel?.data?.created_at;
    const convertDate = new Date(channelcreated);
    const created = this.dateObject(convertDate);
    const today = this.dateObject(newDate);
    // const isUnread = channel?.state?.unreadCount > 0;
    const condition = created >= today;
    // && isUnread;

    const { messages } = channel.state;
    const message = messages[messages.length - 1];
    const messageTimeStamp = message?.created_at ? this.timeObject(message?.created_at) : '';
    // const messagePreview = message ? message?.text.slice(0, 30) : 'no messages yet';

    let order = (orders?.filter((order: any) => order.orderID === orderID))[0];

    return (
      <div className="channel_preview" style={{ height: 'unset' }}>
        {condition && (
          <List component="nav">
            <React.Fragment>
              <ListItem
                alignItems="flex-start"
                onClick={(e) => {
                  setActiveChannel(channel, e);
                  this.handleClick(order);
                  setChannel(channel);
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    alt="Remy Sharp"
                    src="/static/images/avatar/1.jpg"
                    style={channel.state.unreadCount ? { background: '#f50057' } : { background: '#57A946' }}
                  />
                </ListItemAvatar>
                <ListItemText
                  style={{ minWidth: '278px' }}
                  primary={`Order #${orderID}`}
                  secondary={
                    <React.Fragment>
                      <Typography component="span" variant="body2" color="textPrimary">
                        {latestMessage}
                      </Typography>
                      <br />
                      {message ? messageTimeStamp : ''}
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          </List>
        )}
      </div>
    );
  }
}

export default MyChannelPreview;
