import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { OrderCard } from './index';
import { Order, OrderSummary, ICard } from '../types';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
}));

const cards = [
  {
    id: 'totalOrders',
    title: 'Total Orders',
    background: 'linear-gradient(50deg,#ff6ec4,#7873f5)',
  },
  {
    id: 'totalCompleted',
    title: 'Total Completed',
    background: 'linear-gradient(50deg,#a9dc7b,#00796b)',
  },
  {
    id: 'notStarted',
    title: 'Not Started',
    background: 'linear-gradient(50deg,#2ec6ff,#303f9f)',
  },
  {
    id: 'runningLate',
    title: 'Running Late',
    background: 'linear-gradient(50deg,#ffba3a,#e65100)',
  },
];

const groupOrders = (orders: Array<Order>) => {
  let totalOrders = orders.length;
  let totalCompleted = 0;
  let notStarted = 0;
  let runningLate = 0;

  for (const order of orders) {
    if (order.status === 2) {
      notStarted++;
    }
    if (order.status === 1) {
      totalCompleted++;
    }
    if (order.status === 4) {
      runningLate++;
    }
  }

  return { totalOrders, totalCompleted, notStarted, runningLate };
};


const CardGrid = (props: { orders: Array<Order> }) => {
  const classes = useStyles();
  const { orders } = props;

  const [ordersSummary, setOrdersSummary] = useState<OrderSummary>({
    totalOrders: 0,
    totalCompleted: 0,
    notStarted: 0,
    runningLate: 0,
  });

  useEffect(() => {
    const ordersSummary = groupOrders(orders);
    setOrdersSummary(ordersSummary);
  }, [orders]);

  return (
    <Grid container className={classes.root} spacing={2}>
      {cards.map((card: ICard) => {
        const amount = ordersSummary[card.id];
        return <OrderCard amount={amount} key={card.id} cardData={card} />;
      })}
    </Grid>
  );
};

export default CardGrid;
