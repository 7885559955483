import React from 'react';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

import { Container, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '70px 0',
      textAlign: 'center',
    },
    button: {
      margin: theme.spacing(1),
      borderRadius: theme.spacing(1),
    },
  })
);

export default function NotFound() {
  const classes = useStyles();
  const history = useHistory();

  const backToOrders = () => {
    history.push('/orders');
  };
  return (
    <Container className={classes.root}>
      <Typography variant="h1">404 page not found</Typography>
      <Button onClick={() => backToOrders()} size="large" variant="outlined" className={classes.button}>
        Orders
      </Button>
    </Container>
  );
}
