import React, { Fragment, FunctionComponent, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { getTransactions, makeRefund } from '../api';
import CustomModal from './CustomModal';
import TextField from '@material-ui/core/TextField';
import MuiDialogActions from '@material-ui/core/DialogActions';
import * as store from 'store';
import { createToastMessage } from './toast';

interface TransactionsProps {
  orderID: string;
}
interface MakeRefundProps {
  closeRefund: () => void;
  openRefund: boolean;
  orderID: string;
  refetch: Function;
}

interface Refund {
  date: string;
  refund: number;
}

interface TransactionsType {
  deliveryFee: number;
  discount: number;
  foodForward: number;
  netTotal: number;
  orderID: string;
  refund: Refund[];
  serviceFee: number;
  subTotal: number;
  tips: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    list: {
      width: '50%',
      marginRight: theme.spacing(3),
    },

    itemText: {
      maxWidth: '50%',
    },
    button: {
      margin: theme.spacing(1),
      borderRadius: theme.spacing(1),
      float: 'right',
    },
    buttonContaainer: {
      display: 'block',
      height: theme.spacing(7),
    },
    centerAlignDialogActions: {
      justifyContent: 'center',
    },
  })
);

const MakeRefund: FunctionComponent<MakeRefundProps> = (props) => {
  const { closeRefund, openRefund, orderID, refetch } = props;
  const classes = useStyles();

  const [amount, setAmount] = useState('');
  const [reason, setReason] = useState('');
  const accessToken = store.get('spotlightAccessToken');
  const makeRefundMutation = useMutation(makeRefund, {
    onSuccess: () => {
      refetch();
      closeRefund();
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value);
  };
  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(event.target.value);
  };
  const handleMakeRefund = () => {
    if (accessToken) {
      makeRefundMutation.mutate(
        { orderID, reason, amount, accessToken },
        {
          onSuccess: (data) => {
            createToastMessage({ message: data.data.message, error: false });
          },
          onError: (error) => {
            createToastMessage({ message: error, error: true });
          },
        }
      );
    }
  };
  return (
    <Fragment>
      <CustomModal openModal={openRefund} closeModal={closeRefund} title={`Refund order ${orderID}`}>
        <TextField
          onChange={handleAmountChange}
          id="refund-amount"
          label="Refund amount"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          onChange={handleChange}
          margin="normal"
          fullWidth
          id="refund-reason"
          label="Reason for refund"
          variant="outlined"
          multiline
          rows={2}
        />
        <MuiDialogActions classes={{ root: classes.centerAlignDialogActions }}>
          <Button variant="contained" color="primary" onClick={handleMakeRefund}>
            Refund
          </Button>
        </MuiDialogActions>
      </CustomModal>
    </Fragment>
  );
};
const Transactions: FunctionComponent<TransactionsProps> = (props) => {
  const { orderID } = props;
  const accessToken = store.get('spotlightAccessToken');
  const classes = useStyles();
  let transactions: TransactionsType = {
    deliveryFee: 0,
    discount: 0,
    foodForward: 0,
    netTotal: 0,
    orderID: '',
    refund: [],
    serviceFee: 0,
    subTotal: 0,
    tips: 0,
  };

  const [openRefund, setOpenRefund] = useState(false);

  const { data, refetch } = useQuery(['transactions', accessToken, orderID], () =>
    getTransactions({ accessToken, orderID })
  );
  if (data?.data) {
    transactions = data?.data.details;
  }
  const handleClose = () => {
    setOpenRefund(false);
  };
  const handleOpenRefund = () => {
    setOpenRefund(true);
  };

  return (
    <div>
      <div className={classes.buttonContaainer}>
        <Button
          size="large"
          variant="outlined"
          className={classes.button}
          startIcon={<AccountBalanceIcon color="primary" />}
          onClick={handleOpenRefund}
        >
          Make refund
        </Button>
      </div>
      <div className={classes.root}>
        <List
          className={classes.list}
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Order Cost
            </ListSubheader>
          }
        >
          {transactions.subTotal > 0 && (
            <ListItem>
              <ListItemText className={classes.itemText} secondary="Sub Total: " />
              <ListItemText className={classes.itemText} primary={`R ${transactions.subTotal}`} />
            </ListItem>
          )}
          {transactions.deliveryFee > 0 && (
            <ListItem>
              <ListItemText className={classes.itemText} secondary="Delivery Fee: " />
              <ListItemText className={classes.itemText} primary={`R ${transactions.deliveryFee}`} />
            </ListItem>
          )}
          {transactions.serviceFee > 0 && (
            <ListItem>
              <ListItemText className={classes.itemText} secondary="Service Fee: " />
              <ListItemText className={classes.itemText} primary={`R ${transactions.serviceFee}`} />
            </ListItem>
          )}
          {transactions.tips > 0 && (
            <ListItem>
              <ListItemText className={classes.itemText} secondary="Tip: " />
              <ListItemText className={classes.itemText} primary={`R ${transactions.tips}`} />
            </ListItem>
          )}
          <Divider />
          {transactions.netTotal > 0 && (
            <ListItem>
              <ListItemText className={classes.itemText} primary="Total: " />
              <ListItemText className={classes.itemText} primary={`R ${transactions.netTotal}`} />
            </ListItem>
          )}
        </List>
        <List
          className={classes.list}
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Refunds
            </ListSubheader>
          }
        >
          {transactions.refund.length > 0
            ? transactions.refund.map((refund) => (
                <ListItem>
                  <ListItemText
                    className={classes.itemText}
                    secondary={refund?.date ? new Date(refund?.date).toDateString() : 'Date not provided'}
                  />
                  <ListItemText className={classes.itemText} primary={`R ${refund.refund}`} />
                </ListItem>
              ))
            : 'No refunds for this order'}
        </List>
      </div>
      {openRefund && (
        <MakeRefund
          refetch={refetch}
          orderID={transactions.orderID}
          openRefund={openRefund}
          closeRefund={handleClose}
        />
      )}
    </div>
  );
};

export default Transactions;
