import React, { FunctionComponent } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme: Theme) => ({
  modalwrapper: {
    padding: theme.spacing(0),
    height: (props: StypeProps) => props?.height || '75%',
    position: 'absolute',
    right: '-1%',
    top: 32,
    maxWidth: '400px',
    borderRadius: '0px 0px 0px 18px',
  },
  root: {
    margin: 0,
    padding: theme.spacing(1),
    // padding: '20px 0px 10px 10px',
    minWidth: '350px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  content: {
    paddingLeft: '8px',
  },
}));

interface StypeProps {
  height?: string;
}

interface InboxModalProps {
  title: string;
  openModal: boolean;
  closeModal: () => void;
  height?: string;
}

const InboxModal: FunctionComponent<InboxModalProps> = (props) => {
  const { children, openModal, closeModal } = props;
  const classes = useStyles(props);

  return (
    <Dialog
      fullWidth={true}
      open={openModal}
      maxWidth={'sm'}
      onClose={closeModal}
      TransitionComponent={Slide}
      classes={{ paper: classes.modalwrapper }}
    >
      <MuiDialogTitle disableTypography className={classes.root}>
        {/* <Typography variant="h6">{title}</Typography> */}
        {closeModal ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
      <MuiDialogContent className={classes.content}>{children}</MuiDialogContent>
    </Dialog>
  );
};

export default InboxModal;
