import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Product from './ProductAccordion';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    borderRadius: 0,
    marginRight: theme.spacing(2),
  },
  paper: {
    marginBottom: theme.spacing(2),
  },
  expandIcon: {
    marginTop: theme.spacing(3),
  },
  buttons: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },

  itemDetails: {
    margin: theme.spacing(2),
  },
}));

const ShoppingList = ({ shoppingList }) => {
  const classes = useStyles();

  const getProductColor = (item) => {
    let color = 'white';
    switch (item.status) {
      case 1:
        color = 'green';
        break;
      case 3:
        color = 'red';
        break;
      case 0:
        color = 'white';
        break;
      case 2:
      case 4:
        color = 'yellow';
        break;
      case 5:
        color = 'green';
        break;
      default:
        break;
    }

    return color;
  };
  return (
    <div>
      {shoppingList.map((list) => (
        <List className={classes.root}>
          <Typography variant="h6" gutterBottom>
            {list.vendor}
          </Typography>
          <Typography gutterBottom>Total: R {list.cost}</Typography>
          {list.products.map((item) => {
            const productColor = getProductColor(item);

            return <Product itemDetails={item} classes={classes} statusColor={productColor} />;
          })}
        </List>
      ))}
    </div>
  );
};

export default ShoppingList;
