import React, { FunctionComponent, Fragment } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import CustomModal from './CustomModal';
import CustomizedSnackbar from './CustomizedSnackbar';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import { useMutation } from 'react-query';
import { rescheduleOrder } from '../api';
import * as store from 'store';
import { createToastMessage } from './toast';

interface RescheduleOrderProps {
  open: boolean;
  closeReschedule: () => void;
  orderID: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    centerAlignDialogActions: {
      justifyContent: 'center',
    },
  })
);

const timeSlots = [
  {
    id: '1',
    deliveryFrom: 10,
  },
  {
    id: '2',
    deliveryFrom: 11,
  },
  {
    id: '3',
    deliveryFrom: 12,
  },
  {
    id: '4',
    deliveryFrom: 13,
  },
  {
    id: '5',
    deliveryFrom: 14,
  },
  {
    id: '6',
    deliveryFrom: 15,
  },
  {
    id: '7',
    deliveryFrom: 16,
  },
  {
    id: '8',
    deliveryFrom: 17,
  },
];
/**
 * If it is past 5 set the next date
 */
const determineDate = () => {
  const now = new Date();
  if (now.getHours() >= 17) {
    return now.setDate(now.getDate() + 1);
  }

  return now;
};
const RescheduleOrder: FunctionComponent<RescheduleOrderProps> = (props) => {
  const { open, closeReschedule, orderID } = props;
  const classes = useStyles();
  const accessToken = store.get('spotlightAccessToken');
  const dateToUse = determineDate();
  const parsedDate = new Date(dateToUse);
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(parsedDate);
  const [timeSlot, setTimeSlot] = React.useState({ deliveryFrom: '' });

  const rescheduleOrderMutation = useMutation(rescheduleOrder, {
    onSuccess: () => {
      closeReschedule();
    },
  });

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };
  const handleReschedule = () => {
    const formatedDate = selectedDate?.toISOString().slice(0, 10);
    if (accessToken) {
      rescheduleOrderMutation.mutate(
        {
          orderID,
          deliveryDate: formatedDate || '',
          deliveryFrom: timeSlot.deliveryFrom,
          accessToken,
        },
        {
          onSuccess: (data) => {
            createToastMessage({ message: data.data.message, error: false });
          },
          onError: (error) => {
            createToastMessage({ message: error, error: true });
          },
        }
      );
    }
  };

  const handleTimeSlot = (deliveryTime: string) => {
    if (deliveryTime !== null) {
      setTimeSlot({ deliveryFrom: deliveryTime });
    }
  };

  return (
    <Fragment>
      <CustomModal title="Reschedule order" openModal={open} closeModal={closeReschedule}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            disableToolbar
            disablePast
            variant="inline"
            format="yyyy/MM/dd"
            margin="normal"
            id="date-picker-inline"
            label="Date picker inline"
            value={selectedDate}
            onChange={handleDateChange}
            maxDate={new Date().setDate(new Date().getDate() + 5)}
          />
        </MuiPickersUtilsProvider>

        <div>
          <Typography>Time slot</Typography>
          <Grid style={{ marginBottom: '10px' }} container spacing={3}>
            {timeSlots.map((slot) => {
              const currentHour = new Date().getHours();
              const amOrPm = Number(slot.deliveryFrom) >= 12 ? 'pm' : 'am';
              const slotTime = slot.deliveryFrom % 12 || 12;

              return (
                <Grid item xs={3} key={slot.id}>
                  <Chip
                    clickable
                    id={slot.id}
                    disabled={currentHour >= slot.deliveryFrom && new Date().getDate() === selectedDate?.getDate()}
                    label={`${slotTime}:00 ${amOrPm}`}
                    onClick={() => handleTimeSlot(`${slot.deliveryFrom}:00`)}
                    color={timeSlot.deliveryFrom === `${slot.deliveryFrom}:00` ? 'primary' : 'default'}
                  />
                </Grid>
              );
            })}
          </Grid>
          <MuiDialogActions classes={{ root: classes.centerAlignDialogActions }}>
            <Button variant="contained" color="primary" onClick={handleReschedule}>
              Reschedule Order
            </Button>
          </MuiDialogActions>
        </div>
      </CustomModal>
      {(rescheduleOrderMutation.isError || rescheduleOrderMutation.isSuccess) && (
        <CustomizedSnackbar
          response={rescheduleOrderMutation}
          open={rescheduleOrderMutation.isError || rescheduleOrderMutation.isSuccess}
          error={rescheduleOrderMutation.isError}
        />
      )}{' '}
    </Fragment>
  );
};

export default RescheduleOrder;
