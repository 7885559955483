import React, { FunctionComponent, Fragment } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import PhoneInTalkOutlinedIcon from '@material-ui/icons/PhoneInTalkOutlined';
import Button from '@material-ui/core/Button';
import { useQuery, useMutation } from 'react-query';
import { getCustomerDetails } from '../api';
import Skeleton from '@material-ui/lab/Skeleton';
import StatusIcon from './StatusIcon';
import { markCalled } from '../api';
import * as store from 'store';
import onhold from '../images/onhold.png';
import call from '../images/call.png';
import newuser from '../images/newuser.png';
import returncustomer from '../images/returncustomer.png';
import vipcustomer from '../images/vipcustomer.png';
import scooterGreen from '../images/scooter-green.png';
import scooterYellow from '../images/scooter-yellow.png';
import scooterPurple from '../images/scooter-purple.png';
import { createToastMessage } from './toast';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      borderRadius: theme.spacing(2),
      marginBottom: theme.spacing(4),
      // height: '15%',
      minHeight: '15%',
      padding: '10px 25px'
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      width: '70%',
    },

    controls: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },

    button: {
      margin: theme.spacing(1),
      borderRadius: theme.spacing(1),
      fontWeight: 500,
      padding:'15px 20px',
    },
    call: {
      float: 'right',
      marginTop: theme.spacing(1),
      marginLeft: 'auto',
    },

    skeleton: {
      width: 300,
      display: 'flex',
      flexDirection: 'row',
    },
    clientDetails: {
      paddingTop: '0',
    },
  })
);

interface CustomerDetailsProps {
  orderID: string;
  customerType: string;
}

const CustomerDetails: FunctionComponent<CustomerDetailsProps> = (props) => {
  const classes = useStyles();
  const { orderID, customerType } = props;
  const accessToken = store.get('spotlightAccessToken');
  const markCalledMutation = useMutation(markCalled);

  const { isLoading, data, refetch } = useQuery(['customerDetails', accessToken, orderID], () =>
    getCustomerDetails({ accessToken, orderID })
  );

  const handleMarkCalled = () => {
    if (accessToken) {
      markCalledMutation.mutate(
        { orderID, accessToken },
        {
          onSuccess: (data) => {
            refetch();
            createToastMessage({ message: data.data.message, error: false });
          },
          onError: (error) => {
            createToastMessage({ message: error, error: true });
          },
        }
      );
    }
  };

  return (
    <Fragment>
      <Card className={classes.root}>
        <Fragment>
          <div className={classes.details}>
            <CardContent>
              <Typography variant="subtitle1" color="textSecondary">
                {isLoading ? (<Skeleton animation="wave" height={20} width="35%" />
                ):( <b style={{fontSize: '23px', color:'rgba(0, 0, 0, 0.87)'}}>{data?.data.details[0].customerName} {data?.data.details[0].customerSurname}</b>
                )}
              </Typography>
            </CardContent>
            <CardContent className={classes.clientDetails}>
              <Typography variant="subtitle1">
                {isLoading ? (
                  <Skeleton animation="wave" height={20} width="70%" />
                ) : (
                  <div><b>Phone: </b>{data?.data.details[0].customerPhone}</div>
                )}
              </Typography>
              <Typography variant="subtitle1">
                {isLoading ? (
                  <Skeleton animation="wave" height={20} width="70%" />
                ) : (
                  <div><b>Email: </b>{data?.data.details[0].customerEmail}</div>
                )}
              </Typography>
              <Typography variant="subtitle1">
                {isLoading ? (
                  <Skeleton animation="wave" height={20} width="70%" />
                ) : (
                  <div><b>Address: </b>{data?.data.details[0].street} {data?.data.details[0].complex} {data?.data.details[0].city} {data?.data.details[0].postCode}</div>
                )}
              </Typography>
              <div>
                {isLoading ? (
                  <Skeleton animation="wave" height={20} width="70%" />
                ) : (
                  <div style={{ display: 'flex' }}>
                    <Typography style={{ paddingTop: '6px' }} variant="subtitle1">
                      <b style={{paddingRight: '10px'}}>Status:</b>
                    </Typography>
                    <div style={{ display: 'flex' }}>
                      <StatusIcon display={Number(customerType) === 1} imageUrl={newuser} />
                      <StatusIcon display={Number(customerType) === 2} imageUrl={returncustomer} />
                      <StatusIcon display={Number(customerType) === 3} imageUrl={vipcustomer} />
                      <StatusIcon display={data?.data.details[0].driverStatus === 1} imageUrl={scooterGreen} />
                      <StatusIcon display={data?.data.details[0].driverStatus === 2} imageUrl={scooterYellow} />
                      <StatusIcon display={data?.data.details[0].driverStatus === 3} imageUrl={scooterPurple} />
                      <StatusIcon display={data?.data.details[0].onHold === 1} imageUrl={onhold} />
                      <StatusIcon display={data?.data.details[0].hasCalled === 1} imageUrl={call} />
                    </div>
                    
                  </div>
                )}
              </div>
            </CardContent>
          </div>
          <div className={classes.call}>
            <Button
              onClick={handleMarkCalled}
              size="large"
              variant="outlined"
              className={classes.button}
              startIcon={<PhoneInTalkOutlinedIcon color="primary" />}
            >
              {data?.data.details[0].hasCalled === 1 ? 'Placed call' : 'Call'}
            </Button>
          </div>
        </Fragment>
      </Card>
    </Fragment>
  );
};

export default CustomerDetails;
