import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getChatAuth } from '../api';
import * as store from 'store';
import { StreamChat } from 'stream-chat';

const ChatContext: any = React.createContext({});

//-----[ Run get auth api once ]----
const apiCall = async (accessToken: string, isAuthenticated: boolean) => {
  if (!accessToken || accessToken === '' || isAuthenticated) return;
  return await getChatAuth({ accessToken }).then((x) => x.chatAuth);
};

/**
 * Gets the chat client
 * @returns {Promise<void|T>}
 */
const getChatClient = async (user: any) => {
  if (!user) return;

  const client = StreamChat.getInstance('ukufuav9abd9');

  await client.connectUser({ id: user.userID }, user.token);

  const sort: object = [{ last_message_at: -1 }, { updated_at: -1 }];

  const filter = { members: { $in: [user.userID] } };

  const options = { member: true, watch: true, state: true };

  const channel = await client.queryChannels(filter, sort, options);

  return { channel, client, config: { options, filter, sort } };
};

const ChatProvider = ({ children }: any) => {
  const { isAuthenticated } = useAuth0();
  const [accessToken] = useState<string>(store.get('spotlightAccessToken'));
  const [user, setUser] = useState<any>(null);
  const [chatClient, setChatClient] = useState<any>(null);
  const [chatfilter, setChatfilter] = useState<any>(null);
  const [channel, setChannel] = useState<any>(null);
  const [unread, setUnread] = useState<number>(0);
  const [isAssigned, setAssigned] = useState<boolean>(false);
  const [currentOrderID, setCurrentOrderID] = useState<any>(null);
  const [orders, setOrders] = useState<any>([]);

  //-----[ Run get auth api once ]----
  useEffect(() => {
    async function initChatAuth() {
      const auth: any = await apiCall(accessToken, isAuthenticated);
      if (!!auth) setUser(auth);
    }
    if (!user) initChatAuth();
  }, [accessToken, isAuthenticated, user]);

  //----[initialise chat]----
  useEffect(() => {
    async function initChatClient() {
      const streamClient: any = await getChatClient(user);
      setChatClient(streamClient.client);
      setChatfilter(streamClient.config.filter);
      setUnread(streamClient.client?.user?.total_unread_count);
    }
    if (!!user) initChatClient();
  }, [user]);

  //----[Monitor Order Assign]----
  useEffect(() => {
    async function updateCurrentChannel() {
      const streamClient: any = await getChatClient(user).then((x: any) => x.client);
      let getChannel: any = Object.values(streamClient?.activeChannels).find((chat: any) => {
        return `${chat.id}`.includes(currentOrderID);
      });

      console.log('getChannel', getChannel);

      if (channel?.id !== getChannel?.id) {
        setChatClient(streamClient);
        setChannel(getChannel);
      }
    }

    if (!!user && !!currentOrderID && !!isAssigned && !channel) {
      updateCurrentChannel();
    }
  }, [user, isAssigned, currentOrderID, channel]);

  /* client.on('message.new', (event) => {
    console.log('message.new :>> ', event);
    callback.setUnreadCount(client?.user?.total_unread_count ?? 0);
    return createToastMessage('You have a new Message', 'I');
  }); */

  return (
    <ChatContext.Provider
      value={{
        chatClient,
        chatfilter,
        setChannel,
        channel,
        unread,
        setAssigned,
        isAssigned,
        setOrders,
        orders,
        setCurrentOrderID,
        currentOrderID,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

const ChatConsumer = ChatContext.Consumer;

export { ChatContext, ChatProvider, ChatConsumer };
