import React, { MouseEvent, FunctionComponent, useState, useContext } from 'react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AppBar from '@material-ui/core/AppBar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth0 } from '@auth0/auth0-react';
import Grid from '@material-ui/core/Grid';
import CustomIconButton from './CustomIconButon';
import logo from '../images/icon.svg';
import GlobalFilter from './GlobalFilter';
import * as store from 'store';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ChatInbox from './Chat/ChatInbox';
import { ChatContext } from '../contexts/ChatContext';
import { Badge } from '@material-ui/core';
import './Chat/Chat.css';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: 'white',
    color: '#44444F',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flex: 1,
    paddingTop: '12px',
  },
  tabs: {
    minHeight: '95%',
  },
  label: {
    color: '#57A946',
  },
  indicator: {
    backgroundColor: '#57A946',
    marginLeft: 55,
    height: 5,
    maxWidth: 50,
    width: '100%',
    borderRadius: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
  wrapper: {
    textTransform: 'none',
  },
  badgeColor: {
    color: '#11B0F2',
  },
}));

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface HeaderSelectProps {
  tableInstance?: any;
  setOrderID?: any;
  setValue: any;
  value: any;
}

const Header: FunctionComponent<HeaderSelectProps> = (props) => {
  const { unread } = useContext(ChatContext);
  const { tableInstance, setOrderID, setValue, value } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { logout } = useAuth0();
  const open = Boolean(anchorEl);
  const [openChatInbox, setChatInbox] = useState(false);

  const handleMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlLogout = () => {
    store.clearAll();

    logout({ returnTo: window.location.origin });
  };

  const handleCloseChatInbox = () => {
    console.log('chat box close');
    setChatInbox(false);
  };
  const handleOpenChatInbox = () => {
    console.log('chat box open');
    setChatInbox(true);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static">
        <Toolbar>
          <Grid container direction="row" justify="space-between" alignItems="center" spacing={3}>
            <Grid container item xs={4}>
              <CustomIconButton>
                <img alt="logo" src={logo} />
              </CustomIconButton>
              <Typography className={classes.title}>Spotlight</Typography>
            </Grid>

            <Grid container item xs={4}>
              <Tabs
                className={classes.tabs}
                classes={{ indicator: classes.indicator }}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab classes={{ wrapper: classes.wrapper }} label="Orders" {...a11yProps(0)} />
                <Tab classes={{ wrapper: classes.wrapper }} label="My Orders" {...a11yProps(1)} />
              </Tabs>
            </Grid>

            <Grid container item xs={4} justify="flex-end">
              <GlobalFilter tableInstance={tableInstance} setOrderID={setOrderID} />
              <CustomIconButton>
                <Badge badgeContent={unread} className={classes.badgeColor} color="secondary">
                  <ChatBubbleOutlineOutlinedIcon onClick={handleOpenChatInbox} />
                </Badge>
              </CustomIconButton>
              <CustomIconButton>
                <AccountCircle />
              </CustomIconButton>
              <CustomIconButton handleClick={handleMenu}>
                <ExpandMoreIcon />
              </CustomIconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>My account</MenuItem>
                <MenuItem onClick={handlLogout}>Log Out</MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Toolbar>
        {openChatInbox && (
          <div>
            <ChatInbox
              open={openChatInbox}
              closeChatInbox={handleCloseChatInbox}
              tableInstance={tableInstance}
              {...props}
            />
          </div>
        )}
      </AppBar>
    </div>
  );
};

export default Header;
