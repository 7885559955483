import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import { axiosInstance } from '../utils/utils';
import Snackbar from './SnackBar';
import { Customer } from '../types';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    textAlign: 'center',
    padding: '10px',
    fontweight: '500',
    fontSize: '48px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  title: {
    textAlign: 'center',
    margin: theme.spacing(2),
    fontweight: 500,
  },
  buttons: {
    '& > *': {
      margin: theme.spacing(1),
      backgroundColor: '#007bff',
    },
  },
  actions: {
    bottom: theme.spacing(8),
  },
}));

const OrderDetails = ({
  orderId,
  open,
  closeOrderDetails,
  openShoppingList,
  openOrderComment,
  openReceipts,
  openChangeStore,
  accessToken,
  firstName,
  lastName,
  userId,
  agentID,
  onHold,
}: {
  orderId: string;
  open: boolean;
  closeOrderDetails: React.ReactEventHandler<{}>;
  openShoppingList: React.ReactEventHandler<{}>;
  openOrderComment: React.ReactEventHandler<{}>;
  openReceipts: React.ReactEventHandler<{}>;
  openChangeStore: React.ReactEventHandler<{}>;
  viewFulfilledOrder: React.ReactEventHandler<{}>;
  accessToken: string;
  firstName: string;
  lastName: string;
  userId: string;
  agentID: string;
  onHold: number;
}) => {
  // const [customerDetails] = useFetch(fetchCustomerDetails, [], accessToken, { orderId });

  const [customerDetails, setCustomerDetails] = useState<Customer>({
    customerPhone: '',
    customerEmail: '',
    street_number: '',
    street: '',
    complex: '',
    mallCity: '',
    postal_code: '',
    customer_note: '',
    delivery_note: '',
  });
  const [isError, setIsError] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [response, setResponse] = useState({ message: '' });
  const [toggleHold, setToggleHold] = useState(onHold);

  const fetchCustomerDetails = async (orderId: string, token: string) => {
    const response = await axiosInstance.get(`/v1/spotlight/orders/${orderId}/customerDetails`, {
      headers: {
        Authorization: token,
      },
    });
    if (response?.data) {
      setCustomerDetails(response.data);
    }
  };

  useEffect(() => {
    fetchCustomerDetails(orderId, accessToken);
  }, [orderId, accessToken]);

  const handleAssignToMe = () => {
    assignToMe();
  };

  const handleHoldOrder = () => {
    holdOrder();
  };

  const updateToggleHold = () => {
    if (toggleHold === 0) {
      setToggleHold(1);
    } else {
      setToggleHold(0);
    }
  };
  const handleMarkCalled = () => {
    markCalled();
  };

  const assignToMe = async () => {
    try {
      const url = process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderId}/assignOrder`;

      const response = await axiosInstance.post(
        url,
        {},
        {
          headers: {
            Authorization: accessToken,
          },
        }
      );
      setResponse(response?.data);
      setOpenSnack(true);
      if (response.status === 200) {
        setIsError(false);
      } else {
        setIsError(true);
      }
    } catch (error) {
      setResponse(error.response.data);
      setOpenSnack(true);
      setIsError(true);
    }
  };

  const holdOrder = async () => {
    try {
      const url = process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderId}/holdOrder`;

      const response = await axiosInstance.post(
        url,
        {},
        {
          headers: {
            Authorization: accessToken,
          },
        }
      );
      setResponse(response?.data);
      setOpenSnack(true);
      if (response.status === 200) {
        updateToggleHold();
        setIsError(false);
      } else {
        setIsError(true);
      }
    } catch (error) {
      setResponse(error.response.data);
      setOpenSnack(true);
      setIsError(true);
    }
  };
  const markCalled = async () => {
    try {
      const url = process.env.REACT_APP_SLS_API_ENDPOINT + `/v1/spotlight/orders/${orderId}/markCalled`;
      const response = await axiosInstance.post(
        url,
        {},
        {
          headers: {
            Authorization: accessToken,
          },
        }
      );
      setResponse(response?.data);
      setOpenSnack(true);
      if (response.status === 200) {
        setIsError(false);
      } else {
        setIsError(true);
      }
    } catch (error) {
      setResponse(error.response.data);
      setOpenSnack(true);
      setIsError(true);
    }
  };

  const closeSnack = () => {
    setOpenSnack(false);
    setIsError(false);
  };
  const classes = useStyles();
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        // transition={Slide}
        className={classes.modal}
        open={open}
        onClose={closeOrderDetails}
        TransitionComponent={Slide}
        transitionDuration={{ enter: 500, exit: 500 }}
      >
        <MuiDialogTitle disableTypography>
          <Typography className={classes.title} variant="h6">
            {firstName} {lastName} order
          </Typography>
        </MuiDialogTitle>
        <MuiDialogContent dividers>
          <div>
            <div>
              <Typography gutterBottom>Phone: {customerDetails.customerPhone}</Typography>
              <Typography gutterBottom>Email: {customerDetails.customerEmail}</Typography>
              <Typography gutterBottom>Street Number: {customerDetails.street_number} </Typography>
              <Typography gutterBottom>Street: {customerDetails.street}</Typography>
              <Typography gutterBottom>Complex: {customerDetails.complex} </Typography>
              <Typography gutterBottom>City: {customerDetails.mallCity} </Typography>
              <Typography gutterBottom>Postal Code:{customerDetails.postal_code} </Typography>
              <Typography gutterBottom>Customer Note: {customerDetails.customer_note} </Typography>
              <Typography gutterBottom>Delivery Note: {customerDetails.delivery_note} </Typography>
            </div>
          </div>
          <div className={classes.actions}>
            <Typography className={classes.title} variant="h6">
              You're about to action order {orderId}
            </Typography>
            <div className={classes.buttons}>
              <Button
                disabled={agentID === userId}
                onClick={handleAssignToMe}
                size="small"
                variant="contained"
                color="primary"
              >
                Assign to me
              </Button>
              <Button size="small" variant="contained" onClick={openChangeStore} color="primary">
                Change Store
              </Button>
              <Button size="small" variant="contained" onClick={openShoppingList} color="primary">
                View shopping list
              </Button>
              <Button size="small" variant="contained" onClick={openOrderComment} color="primary">
                Comment
              </Button>
            </div>
            <div className={classes.buttons}>
              <Button size="small" variant="contained" onClick={openReceipts} color="primary">
                View receipts
              </Button>
              <Button size="small" variant="contained" onClick={handleHoldOrder} color="primary">
                {toggleHold === 0 ? 'Hold' : 'Unhold'} order
              </Button>
              <Button size="small" variant="contained" onClick={handleMarkCalled} color="primary">
                Place call
              </Button>
            </div>
          </div>
        </MuiDialogContent>
        <MuiDialogActions>
          <Button variant="contained" onClick={closeOrderDetails} color="primary">
            Close
          </Button>
        </MuiDialogActions>
      </Dialog>
      {openSnack && <Snackbar message={response?.message} open={openSnack} error={isError} closeSnack={closeSnack} />}
    </div>
  );
};

export default OrderDetails;
