import React, { FunctionComponent, Fragment } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      borderRadius: theme.spacing(2),
      marginBottom: theme.spacing(4),
      minHeight: '15%',
      padding: '10px 25px',
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      width: '80%',
    },

    controls: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },

    skeleton: {
      width: 300,
      display: 'flex',
      flexDirection: 'row',
    },
    clientDetails: {
      paddingTop: '0',
    },
  })
);

interface Order {
  orderID: string;
  isCompleted: string;
  orderTypeID: string;
  shopName: string;
  startTime: string;
  shoppingCompleted: string;
  deliveryStarted: string;
  deliveryCompleted: string;
  agentID: string;
}

const OrderDetailSection: FunctionComponent<Order> = (props) => {
  const classes = useStyles();
  const { orderID, shopName, startTime, shoppingCompleted, deliveryStarted, deliveryCompleted } = props;

  return (
    <Fragment>
      <Card className={classes.root}>
        <Fragment>
          <div className={classes.details}>
            <CardContent>
              <Typography variant="subtitle1" color="textSecondary">
                <b style={{ fontSize: '23px', color: 'rgba(0, 0, 0, 0.87)' }}>Order {orderID}</b>
              </Typography>
            </CardContent>
            <CardContent className={classes.clientDetails}>
              <Typography variant="subtitle1">
                {!orderID ? (
                  <Skeleton animation="wave" height={20} width="70%" />
                ) : (
                  <div>
                    <b style={{ fontSize: '15px' }}>Store: </b>
                    {shopName || 'xxx'}
                  </div>
                )}
              </Typography>
              <Typography variant="subtitle1">
                {!orderID ? (
                  <Skeleton animation="wave" height={20} width="70%" />
                ) : (
                  <div>
                    <b style={{ fontSize: '15px' }}>Shopping started: </b>
                    {startTime || 'xxx'}
                  </div>
                )}
              </Typography>
              <Typography variant="subtitle1">
                {!orderID ? (
                  <Skeleton animation="wave" height={20} width="70%" />
                ) : (
                  <div>
                    <b style={{ fontSize: '15px' }}>Shopping completed: </b>
                    {shoppingCompleted || 'xxx'}
                  </div>
                )}
              </Typography>
              <Typography variant="subtitle1">
                {!orderID ? (
                  <Skeleton animation="wave" height={20} width="70%" />
                ) : (
                  <div>
                    <b style={{ fontSize: '15px' }}>Delivery started: </b>
                    {deliveryStarted || 'xxx'}
                  </div>
                )}
              </Typography>
              <Typography variant="subtitle1">
                {!orderID ? (
                  <Skeleton animation="wave" height={20} width="70%" />
                ) : (
                  <div>
                    <b style={{ fontSize: '15px' }}>Devlivery completed: </b>
                    {deliveryCompleted || 'xxx'}
                  </div>
                )}
              </Typography>
            </CardContent>
          </div>
        </Fragment>
      </Card>
    </Fragment>
  );
};

export default OrderDetailSection;
