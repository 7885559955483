import React, { ChangeEvent, FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 150,
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  menuPaper: {
    maxHeight: 150,
  },
}));

interface VendorFilterProps {
  AppState: any;
}
const VendorFilter: FunctionComponent<VendorFilterProps> = (props) => {
  const { AppState } = props;
  const classes = useStyles();
  const [state, dispatch] = AppState();
  const handleVendorChange = (event: ChangeEvent<{ value: unknown }>): void => {
    dispatch({ type: 'SET_VENDOR', payload: event.target.value as string });
  };

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="mall-label">Vendor</InputLabel>
        <Select
          label="Choose mall"
          labelId="mall-select-label"
          id="mall-select"
          value={state.vendor}
          onChange={handleVendorChange}
          MenuProps={{
            classes: { paper: classes.menuPaper },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Onecart">Onecart</MenuItem>
          <MenuItem value="Woolworths">Woolworths</MenuItem>
          <MenuItem value="Ava">Avo</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default VendorFilter;
