import React, { Fragment, FunctionComponent } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import * as store from 'store';
import { createToastMessage } from './toast';

import { useQuery, useMutation } from 'react-query';
import { changeStore, getStores } from '../api';

import CustomModal from './CustomModal';

interface Store {
  name: string;
}
interface ChangeOrderProps {
  open: boolean;
  closeChangeStore: () => void;
  orderID: string;
}

const ChangeStore: FunctionComponent<ChangeOrderProps> = (props) => {
  const { open, closeChangeStore, orderID } = props;
  const accessToken = store.get('spotlightAccessToken');
  let stores = [];
  const changeStoreMutation = useMutation(changeStore);
  const { data } = useQuery<any, Error>(['stores', accessToken], () => getStores({ accessToken }), {
    enabled: !!accessToken,
  });
  if (data?.data) {
    stores = data?.data.details;
  }
  const handleClick = (storeName: string) => {
    if (accessToken) {
      changeStoreMutation.mutate(
        { orderID, storeName, accessToken },
        {
          onSuccess: (data) => {
            createToastMessage({ message: data.data.message, error: false });
          },
          onError: (error) => {
            createToastMessage({ message: error, error: true });
          },
        }
      );
    }
  };

  return (
    <Fragment>
      <CustomModal title="Change store" openModal={open} closeModal={closeChangeStore}>
        <List component="nav">
          {stores.map((store: Store, index: any) => (
            <React.Fragment key={index}>
              <ListItem button onClick={() => handleClick(store.name)}>
                <ListItemText primary={store.name} />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </CustomModal>
    </Fragment>
  );
};

export default ChangeStore;
