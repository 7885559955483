import { lighten, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import MyOrders from './MyOrders';
import { User } from '../types';
import { FilterValue, Row } from 'react-table';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: 'flex',
    '& > *': {
      margin: theme.spacing(3),
    },
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

interface TableToolbarProps {
  numSelected: number;
  preGlobalFilteredRows: Array<Row>;
  setGlobalFilter: (filterValue: FilterValue) => void;
  globalFilter: string;
  handleEndDateChange: (date: MaterialUiPickersDate) => void;
  handleStartDateChange: (date: MaterialUiPickersDate) => void;
  fetchDashboardData: React.MouseEventHandler<HTMLButtonElement>;
  startDate: string;
  endDate: string;
  setFilter: Function;
  user: User;
}

const TableToolbar = (props: TableToolbarProps) => {
  const classes = useToolbarStyles();
  const {
    numSelected,

    setFilter,
    user,
  } = props;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {/* <DateFilter
        // startDate={startDate}
        // endDate={endDate}
        // handleEndDateChange={handleEndDateChange}
        // handleStartDateChange={handleStartDateChange}
        // fetchDashboardData={fetchDashboardData}
      /> */}
      {/* <StatusSelect /> */}
      <MyOrders setFilter={setFilter} user={user} />
      {/* <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      /> */}
    </Toolbar>
  );
};

TableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  setGlobalFilter: PropTypes.func.isRequired,
  preGlobalFilteredRows: PropTypes.array.isRequired,
  globalFilter: PropTypes.string,
};

export default TableToolbar;
