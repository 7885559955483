import React, { Fragment } from 'react';
import Table from '@material-ui/core/Table';
import MuiTableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, createStyles, withStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import Button from '@material-ui/core/Button';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import logo from '../images/OneCart.svg';

const useStyles = makeStyles((theme) =>
  createStyles({
    invoiceBox: {
      maxWidth: '800px',
      margin: 'auto',
      padding: '30px',
      border: '1px solid #eee',
      boxShadow: '0 0 10px rgba(0, 0, 0, .15)',
      fontSize: '16px',
      lineHeight: '24px',
    },
    productCell: {
      paddingBottom: '0px',
      paddingTop: '0px',
    },
    button: {
      margin: theme.spacing(1),
      borderRadius: theme.spacing(1),
    },
    call: {
      marginTop: theme.spacing(3),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    totals: {
      float: 'left',
    },
    tableHeader: {},
  })
);

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell);
const Invoice = (props) => {
  const { data, customerData } = props;
  const classes = useStyles();
  const orderDetais = data.data.details;
  const invoiceRef = React.createRef();

  const printInvoicePdf = () => {
    const html = invoiceRef.current;

    html2canvas(html).then((canvas) => {
      let pdf = new jsPDF('p', 'mm', 'a4');
      pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 211, 298);
      pdf.save('Invoice.pdf');
    });
  };

  return (
    <Fragment>
      <div ref={invoiceRef} className={classes.invoiceBox}>
        <Table>
          <TableRow colSpan={4}>
            <TableCell colSpan={4}>
              <Table>
                <TableRow colSpan={4}>
                  <TableCell colSpan={2}>
                    <img alt="logo" src={logo} />
                    <br />
                    <br />
                    Invoice#
                  </TableCell>
                  <TableCell colSpan={2}>
                    Reg: 2016/262246/07
                    <br />
                    VAT: 4680283324
                    <br />
                    2ND Floor,
                    <br /> Block B Sandton CloseSandown <br />
                    2196
                  </TableCell>
                </TableRow>
              </Table>
            </TableCell>
          </TableRow>

          <TableRow colSpan={4}>
            <TableCell colSpan={4}>
              <Table>
                <TableRow colSpan={4}>
                  <TableCell colSpan={2}>
                    Customer Name: {customerData.customerName} {customerData.customerSurname}
                    <br />
                    Email: {customerData.customerEmail}
                    <br />
                    Phone: {customerData.customerPhone}
                    <br />
                    Date: {new Date().toDateString()}
                    <br />
                    Shipping Address:  {customerData.street}
                  </TableCell>
                </TableRow>
              </Table>
            </TableCell>
          </TableRow>
          <TableRow className={classes.tableHeader}>
            <MuiTableCell variant="head">Product</MuiTableCell>
            <MuiTableCell variant="head">Qty</MuiTableCell>
            <MuiTableCell variant="head">Unit Price</MuiTableCell>
            <MuiTableCell variant="head">Total</MuiTableCell>
          </TableRow>
          {orderDetais.orderDetails.vendors.map((vendor) => {
            return (
              <Fragment>
                {vendor.foundItems.length > 0 && (
                  <Fragment>
                    {vendor.foundItems.map((item) => (
                      <TableRow>
                        <MuiTableCell classes={{ root: classes.productCell }}>{item.name}</MuiTableCell>
                        <MuiTableCell classes={{ root: classes.productCell }}>{item.orderQuantity}</MuiTableCell>
                        <MuiTableCell classes={{ root: classes.productCell }}>R{item.price}</MuiTableCell>
                        <MuiTableCell classes={{ root: classes.productCell }}>
                          R{(item.price * item.orderQuantity).toFixed(2)}
                        </MuiTableCell>
                      </TableRow>
                    ))}
                  </Fragment>
                )}
                {vendor.substitutedItems.length > 0 && (
                  <Fragment>
                    {vendor.substitutedItems.map((item) => (
                      <TableRow>
                        <MuiTableCell classes={{ root: classes.productCell }}>{item.substitutionName}</MuiTableCell>
                        <MuiTableCell classes={{ root: classes.productCell }}>{item.substitutionQuantity}</MuiTableCell>
                        <MuiTableCell classes={{ root: classes.productCell }}>R{item.substitutionPrice}</MuiTableCell>
                        <MuiTableCell classes={{ root: classes.productCell }}>
                          R{(item.substitutionPrice * item.substitutionQuantity).toFixed(2)}
                        </MuiTableCell>
                      </TableRow>
                    ))}
                  </Fragment>
                )}
              </Fragment>
            );
          })}
          <TableRow>
            <MuiTableCell colSpan={3} variant="head">
              Sub Total
            </MuiTableCell>

            <MuiTableCell variant="head"> R{orderDetais.subTotal}</MuiTableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}></TableCell>
            <TableCell variant="head" align="right">
              Delivery Fee:
            </TableCell>
            <TableCell>R{orderDetais.deliveryFee}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}></TableCell>
            <TableCell variant="head" align="right">
              Service Fee:
            </TableCell>
            <TableCell>R{orderDetais.serviceFee}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}></TableCell>
            <TableCell variant="head" align="right">
              Total:
            </TableCell>
            <TableCell>R{orderDetais.total.toFixed(2)}</TableCell>
          </TableRow>
        </Table>
      </div>
      <div className={classes.call}>
        <Button
          onClick={printInvoicePdf}
          size="large"
          variant="outlined"
          className={classes.button}
          startIcon={<GetAppIcon color="primary" />}
        >
          Download Invoice
        </Button>
      </div>
    </Fragment>
  );
};

export default Invoice;
