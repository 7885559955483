import React, { FunctionComponent, useEffect, useContext } from 'react';
import { ChatContext } from '../../../contexts/ChatContext';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import MessagingInput from './MessagingInput/MessagingInput';
import TypingIndicator from './TypingIndicator/TypingIndicator';
import { Chat, Channel, Thread, Window, MessageList, MessageInput } from 'stream-chat-react';
import { useParams } from 'react-router-dom';
import 'stream-chat-react/dist/css/index.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '67vh',
      paddingTop: '0px',
      theme: 'messaging light',
    },
  })
);
interface ParamTypes {
  orderID: string;
}

const Messaging: FunctionComponent<any> = () => {
  const classes = useStyles();
  const { chatClient, channel, setCurrentOrderID } = useContext(ChatContext);

  let { orderID } = useParams<ParamTypes>();

  useEffect(() => {
    setCurrentOrderID(orderID);
  }, [setCurrentOrderID, orderID]);

  if (!chatClient) {
    return (
      <h4 style={{ textAlign: 'center' }}>
        Could not connect to chat client
        <br />
        Try freshing page
      </h4>
    );
  }

  return (
    <>
      {!channel ? (
        <>
          <h4 style={{ textAlign: 'center' }}>Chat will be available when a shopper has started this order!</h4>
        </>
      ) : (
        <Chat client={chatClient} theme={classes.root}>
          <Channel channel={channel}>
            <Window>
              <MessageList TypingIndicator={TypingIndicator} />
              <MessageInput Input={MessagingInput} />
            </Window>
            <Thread />
          </Channel>
        </Chat>
      )}
    </>
  );
};

export default Messaging;
