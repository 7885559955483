import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import {User} from '../types'
interface CheckBox {
  user:User
  setFilter: Function;
}

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const MyOrders = ({ setFilter, user }:CheckBox) => {
  const userId = user.sub.split('|').pop();
  const [checked, setChecked] = useState(false);
  const handleChange = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    setFilter('agentID', checked ? userId : undefined);
  }, [checked, userId, setFilter]);
  return (
    <div>
      <FormControlLabel
        control={<GreenCheckbox checked={checked} onChange={handleChange} name="myOrdes" />}
        label="My orders"
        labelPlacement="start"
      />
    </div>
  );
};

export default MyOrders;
