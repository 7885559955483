import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Redirect, Route } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated)
    return (
      <Redirect
        to={{
          pathname: '/',
          hash: 'login',
          state: { from: rest.location },
        }}
      />
    );

  return <Route render={(props) => <Component {...props} />} {...rest} />;
};

export default ProtectedRoute;
