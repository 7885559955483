import React, { useState, useRef, useReducer, useEffect, useContext } from 'react';
import { ChatContext } from '../contexts/ChatContext';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { useQuery } from 'react-query';
import { TableInstance } from 'react-table';
import * as store from 'store';

import Header from '../components/Header';
import FilterResults from '../components/FilterResults';
import OrdersTable from '../components/OrdersTable';
import LateOrders from '../components/LateOrders';
import OrdersSummary from '../components/OrdersSummary';
import { getOrders } from '../api';
import { OrderSummary } from '../types';

import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { useAuth0 } from '@auth0/auth0-react';
import MyOrdersTable from '../components/MyOrdersTable';

interface OrdersProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      borderRadius: theme.spacing(1),
      color: theme.palette.text.secondary,
    },
    root: {
      flexGrow: 1,
    },
    tabs: {
      minHeight: '95%',
    },
    label: {
      color: '#57A946',
    },
    indicator: {
      backgroundColor: '#57A946',
      marginLeft: 55,
      height: 5,
      maxWidth: 50,
      width: '100%',
      borderRadius: theme.spacing(1),
      display: 'flex',
      justifyContent: 'center',
    },
    wrapper: {
      textTransform: 'none',
    },
  })
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useForceUpdate = () => useReducer((state) => !state, false)[1];

const createSharedState = (reducer: any, initialState: any) => {
  const subscribers: any = [];
  let state = initialState;
  const dispatch = (action: any) => {
    state = reducer(state, action);
    subscribers.forEach((callback: any) => callback());
  };
  const useSharedState = () => {
    const forceUpdate = useForceUpdate();
    useEffect(() => {
      const callback = () => forceUpdate();
      subscribers.push(callback);
      callback(); // in case it's already updated
      const cleanup = () => {
        const index = subscribers.indexOf(callback);
        subscribers.splice(index, 1);
      };
      return cleanup;
    }, [forceUpdate]);
    return [state, dispatch];
  };
  return useSharedState;
};

const initialState = {
  vendor: 'All',
  startDate: new Date().toISOString().slice(0, 10),
  endDate: new Date().toISOString().slice(0, 10),
  orderID: null,
  selectedStores: [],
  selectedStatus: [],
};

const reducer = (state: any, action: any) => {
  console.log({ action });

  switch (action.type) {
    case 'SET_START_DATE':
      return { ...state, startDate: action.payload };
    case 'SET_END_DATE':
      return { ...state, endDate: action.payload };
    case 'SET_ORDER_ID':
      return { ...state, orderID: action.payload };
    case 'SET_VENDOR':
      return { ...state, vendor: action.payload };
    case 'SET_SELECTED_STORES':
      return { ...state, selectedStores: [...action.payload] };
    case 'SET_SELECTED_STATUS':
      return { ...state, selectedStatus: [...action.payload] };

    default:
      return state;
  }
};

const AppState = createSharedState(reducer, initialState);

const vendors = [
  { name: 'All', value: 0 },
  { name: 'Onecart', value: 1 },
  { name: 'Woolworths', value: 2 },
  { name: 'Ava', value: 3 },
];

const Orders: React.FC<OrdersProps> = (props) => {
  const tableInstance = useRef<TableInstance>(null);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const setFilter = tableInstance.current?.setFilter;
  const { user: userInfo } = useAuth0();
  const { setOrders } = useContext(ChatContext);

  const accessToken = store.get('spotlightAccessToken');

  const [state, dispatch] = AppState();
  const { vendor, selectedStores, selectedStatus } = state;

  const startDate = state.startDate;
  const endDate = state.endDate;
  const orderID = state.orderID;
  const { isLoading, data } = useQuery(
    ['orders', endDate, startDate, orderID, accessToken],
    () => getOrders({ startDate, endDate, orderID, accessToken }),
    {
      refetchInterval: 180000,
    }
  );

  const agentId = userInfo?.sub.replace(/.*\|/, '');
  const allOrders = data?.data.details || [];
  const myOrders = allOrders.filter((o) => o.agentID === agentId);

  useEffect(() => {
    if (setFilter) {
      const selectedVendor = vendors.find((ven) => ven.name === vendor);
      setFilter('orderTypeID', vendor !== 'All' ? selectedVendor?.value : undefined);
    }
  }, [vendor, setFilter]);

  useEffect(() => {
    if (setFilter) {
      setFilter('shopName', selectedStores?.length ? selectedStores : undefined);
    }
  }, [selectedStores, setFilter]);

  useEffect(() => {
    if (setFilter) {
      setFilter('status', selectedStatus?.length ? selectedStatus : undefined);
    }
  }, [selectedStatus, setFilter]);

  useEffect(() => {
    setOrders(data?.data.details || []);
  }, [data, tableInstance, setOrders]);

  // orders summary
  const [ordersSummary, setOrdersSummary] = useState<OrderSummary>({
    totalOrders: 0,
    totalCompleted: 0,
    notStarted: 0,
    runningLate: 0,
    inProgress: 0,
    cancelled: 0,
  });

  const handleStartDateChange = (value: Date | null) => {
    if (value) {
      dispatch({ type: 'SET_START_DATE', payload: value.toISOString().slice(0, 10) });
    }
  };
  const handleEndDateChange = (value: Date | null) => {
    if (value) {
      dispatch({ type: 'SET_END_DATE', payload: value.toISOString().slice(0, 10) });
    }
  };
  const handleOrderIDChange = (value: string | null) => {
    dispatch({ type: 'SET_ORDER_ID', payload: value });
  };

  return (
    <React.Fragment>
      <Header
        tableInstance={tableInstance}
        setOrderID={handleOrderIDChange}
        value={value}
        setValue={setValue}
        {...props}
      />
      <Container style={{ paddingTop: '20px' }} maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <OrdersSummary tableInstance={tableInstance} ordersSummary={ordersSummary} />
          </Grid>
          <Grid item xs={2}>
            <LateOrders lateOrders={ordersSummary.runningLate} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Paper className={classes.paper}>
              <Typography>Filter results</Typography>
              <FilterResults
                setStartDate={handleStartDateChange}
                setEndDate={handleEndDateChange}
                startDate={state.startDate}
                endDate={state.endDate}
                AppState={AppState}
              />
            </Paper>
          </Grid>
          <TabPanel value={value} index={0}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                {isLoading ? (
                  'Loading...'
                ) : (
                  <OrdersTable orders={data?.data.details} ref={tableInstance} setOrdersSummary={setOrdersSummary} />
                )}
              </Paper>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                {isLoading ? (
                  'Loading...'
                ) : (
                  <MyOrdersTable orders={myOrders} ref={tableInstance} setOrdersSummary={setOrdersSummary} />
                )}
              </Paper>
            </Grid>
          </TabPanel>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Orders;
