import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

interface SnackbarProps {
  response: any;
  open: boolean;
  error: boolean;
}
function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function CustomizedSnackbar(props: SnackbarProps) {
  const { response, open, error } = props;
  let message: string = '';

  if (error) {
    const apieError = response?.error?.response?.data?.message;
    const networkError = response?.error?.message;
    message = apieError ? apieError : networkError;
  } else {
    message = response.data.data.message;
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={1000}
      key={Math.random()}
    >
      <Alert severity={error ? 'error' : 'success'}>{message}</Alert>
    </Snackbar>
  );
}
