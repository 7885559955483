import React, { FunctionComponent } from 'react';
import IconButton from '@material-ui/core/IconButton';

interface IconButtonProps {
  handleClick?: React.ReactEventHandler<{}>;
}
const CustomIconButton: FunctionComponent<IconButtonProps> = (props) => {
  return <IconButton onClick={props.handleClick}>{props.children}</IconButton>;
};

export default CustomIconButton;
