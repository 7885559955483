import React, { FunctionComponent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 200,
      background: '#D65745',
      borderRadius: theme.spacing(2),
      height: '100%',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 50,
      textAlign: 'center',
      color: 'white',
    },
    pos: {
      color: 'white',
      textAlign: 'center',
    },
  })
);

interface LateOrderProps {
  lateOrders: number;
}

const LateOrder: FunctionComponent<LateOrderProps> = (props) => {
  const classes = useStyles();
  const { lateOrders } = props;

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h2" className={classes.title} gutterBottom>
          {lateOrders}
        </Typography>
        <Typography className={classes.pos}>Late Orders</Typography>
      </CardContent>
    </Card>
  );
};

export default LateOrder;
