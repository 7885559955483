import React, { useImperativeHandle, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useHistory } from 'react-router-dom';
import { useTable, useFilters, useGlobalFilter, Cell, useSortBy } from 'react-table';
import { ProgressBar, OrderStatus } from '../components';
import { OrderSummary } from '../types';
import { groupOrders } from '../utils/utils';
interface Column {
  accessor: any;
  Header: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
  Cell?: any;
  show?: boolean;
  filter?: any;
}

const columns: Column[] = [
  { accessor: 'orderID', Header: 'Order ID', minWidth: 100 },
  {
    accessor: 'status' as keyof Order,
    Header: 'Order Status',
    minWidth: 50,
    filter: 'multiple',
    Cell: (cellInfo: Cell<Order>) => {
      const status = cellInfo.row.original.status;

      return <OrderStatus status={Number(status)} />;
    },
  },
  { accessor: 'firstName', Header: 'First Name', minWidth: 100 },
  { accessor: 'lastName', Header: 'Last Name', minWidth: 100 },
  { accessor: 'totalItems', Header: 'Total Items', minWidth: 50 },
  {
    accessor: 'percent_complete',
    Header: 'Percentage',
    minWidth: 100,
    Cell: (cellInfo: Cell<Order>) => {
      const completedItems = cellInfo.row.original.completedItems;
      const totalItems = cellInfo.row.original.totalItems;
      const progress = Math.round((Number(completedItems) / Number(totalItems)) * 100);

      return <ProgressBar completed={progress} />;
    },
  },
  { accessor: 'deliveryFrom', Header: 'Delivery Time', minWidth: 70 },
  { accessor: 'agent', Header: 'Agent', minWidth: 100 },
  { accessor: 'shopper', Header: 'Shopper', minWidth: 100 },
  { accessor: 'driver', Header: 'Driver', minWidth: 100 },
  { accessor: 'shopName', Header: 'Store', minWidth: 100, filter: 'multiple' },
  { accessor: 'startTime', Header: 'Time Started', minWidth: 100 },
  { accessor: 'shoppingCompleted', Header: 'Time Completed', minWidth: 100 },
  { accessor: 'deliveryStarted', Header: 'Delivery Started', minWidth: 100 },
  { accessor: 'deliveryCompleted', Header: 'Delivery Completed', minWidth: 100 },
  { accessor: 'orderTypeID', Header: 'order type', minWidth: 100, show: false },
];

interface Order {
  orderID: string;
  orderCount: string;
  firstName: string;
  lastName: string;
  totalItems: string;
  completedItems: string;
  receivedTime: string;
  deliveryDate: string;
  deliveryFrom: string;
  deliveryTo: string;
  shoppingCompleted: string;
  onHold: string;
  hasCalled: string;
  agentID: string;
  isCompleted: string;
  shopper: string;
  startTime: string;
  agent: string;
  isOwner: string;
  shopName: string;
  state: string;
  deliveryStarted: string;
  deliveryCompleted: string;
  driver: string;
  driverStatus: string;
  magic: string;
  agentOrderTypeID: string;
  orderTypeID: string;
  customerType: string;
  status: string;
}
interface TableProps {
  orders: Order[];
  setOrdersSummary: React.Dispatch<React.SetStateAction<OrderSummary>>;
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '69vh',
  },
  container: {
    maxHeight: '69vh',
  },
});

const MyOrdersTable = React.forwardRef((props: TableProps, ref: any) => {
  const { orders, setOrdersSummary } = props;
  const filterTypes = React.useMemo(
    () => ({
      multiple: (rows: any, id: any, filterValue: string) => {
        return rows.filter((row: any) => {
          const rowValue = row.values[id];
          return rowValue !== undefined ? filterValue.includes(rowValue) : true;
        });
      },
    }),
    []
  );
  const instance = useTable(
    {
      columns,
      data: orders,
      filterTypes,
      autoResetFilters: false,
      initialState: {
        hiddenColumns: columns.filter((col) => col.show === false).map((col) => col.accessor),
      },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, flatRows } = instance;

  // return table instance
  useImperativeHandle(ref, () => instance);

  const classes = useStyles();

  const history = useHistory();

  const viewOrderDetails = (
    orderID: string,
    shopper: string,
    isCompleted: string,
    customerType: string,
    orderTypeID: string,
    shopName: string,
    startTime: string,
    shoppingCompleted: string,
    deliveryStarted: string,
    deliveryCompleted: string,
    agentID: string
  ) => {
    history.push(`/orders/${orderID}`, {
      shopper,
      isCompleted,
      customerType,
      orderTypeID,
      shopName,
      startTime,
      shoppingCompleted,
      deliveryStarted,
      deliveryCompleted,
      agentID,
    });
  };
  useEffect(() => {
    const ordersSummary = groupOrders(flatRows);
    setOrdersSummary(ordersSummary);
  }, [setOrdersSummary, flatRows]);
  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table {...getTableProps()} stickyHeader aria-label="sticky table">
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                  </TableCell>
                ))}
                {/* {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))} */}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  onClick={() =>
                    viewOrderDetails(
                      row.original.orderID,
                      row.original.shopper,
                      row.original.isCompleted,
                      row.original.customerType,
                      row.original.orderTypeID,
                      row.original.shopName,
                      row.original.startTime,
                      row.original.shoppingCompleted,
                      row.original.deliveryStarted,
                      row.original.deliveryCompleted,
                      row.original.agentID
                    )
                  }
                  hover
                  tabIndex={-1}
                >
                  {row.cells.map((cell) => {
                    return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>;
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
});

export default MyOrdersTable;
