import React, { useState, FunctionComponent, useContext } from 'react';
import { ChatContext } from '../contexts/ChatContext';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import StorefrontIcon from '@material-ui/icons/Storefront';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PauseIcon from '@material-ui/icons/Pause';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import RescheduleOrder from './RescheduleOrder';
import ChangeStore from './ChangeStore';
import { assignOrder, holdOrder, resetOrder, postToPicup } from '../api';
import * as store from 'store';
import { createToastMessage } from './toast';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      borderRadius: theme.spacing(2),
      backgroundColor: '#F4F4F4',
      maxHeight: '100%',
      verticalAlign: 'middle',
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },

    controls: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },

    button: {
      height: 45,
      margin: theme.spacing(1),
      borderRadius: theme.spacing(1),
      fontWeight: 450,
    },
    call: {
      float: 'right',
      margin: theme.spacing(3),
      marginLeft: 'auto',
    },

    colorRed: {
      color: 'white',
      background: '#D65745',
      border: 'none',
      '&:hover': {
        color: '#D65745',
        border: '1px solid #D65745',
        transform: 'scale(1.0)',
      },
    },
    colorOrange: {
      color: 'white',
      background: '#EEAA23',
      border: 'none',
      '&:hover': {
        color: '#EEAA23',
        border: '1px solid #EEAA23',
      },
    },
    colorGreen: {
      color: 'white',
      background: '#57A946',
      border: 'none',
      '&:hover': {
        color: '#57A946',
        border: '1px solid #57A946',
      },
    },
  })
);

interface ActionButtonsProps {
  orderID: string;
  shopper: string;
}

const OrderActionButtons: FunctionComponent<ActionButtonsProps> = (props) => {
  const { setChannel, setAssigned, setCurrentOrderID } = useContext(ChatContext);
  const classes = useStyles();
  const history = useHistory();
  const { orderID, shopper } = props;
  const accessToken = store.get('spotlightAccessToken');
  const [openReschedule, setOpenReschedule] = useState(false);
  const [openChangeStore, setOpenChangeStore] = useState(false);

  const handleCloseReschedule = () => {
    setOpenReschedule(false);
  };
  const handleOpenReschedule = () => {
    setOpenReschedule(true);
  };
  const handleCloseChangeStore = () => {
    setOpenChangeStore(false);
  };
  const handleOpenChangeStore = () => {
    setOpenChangeStore(true);
  };

  const assignOrderMutation = useMutation(assignOrder);
  const holdOrderMutation = useMutation(holdOrder);
  const resetOrderMutation = useMutation(resetOrder);
  const postToPicupMutation = useMutation(postToPicup);

  const handleAssignOrder = () => {
    if (accessToken) {
      assignOrderMutation.mutate(
        { orderID, accessToken },
        {
          onSuccess: (data) => {
            createToastMessage({ message: data.data.message, error: false });
            setAssigned(true);
          },
          onError: (error) => {
            createToastMessage({ message: error, error: true });
          },
        }
      );
    }
  };

  const handleHoldOrder = () => {
    if (accessToken) {
      holdOrderMutation.mutate(
        { orderID, accessToken },
        {
          onSuccess: (data) => {
            createToastMessage({ message: data.data.message, error: false });
          },
          onError: (error) => {
            createToastMessage({ message: error, error: true });
          },
        }
      );
    }
  };
  const handleResetOrder = () => {
    if (accessToken) {
      const resetShopper = shopper ? 1 : 0;
      resetOrderMutation.mutate(
        { orderID, accessToken, resetShopper },
        {
          onSuccess: (data) => {
            createToastMessage({ message: data.data.message, error: false });
          },
          onError: (error) => {
            createToastMessage({ message: error, error: true });
          },
        }
      );
    }
  };
  const handlePicup = () => {
    if (accessToken) {
      postToPicupMutation.mutate(
        { orderID, accessToken },
        {
          onSuccess: (data) => {
            createToastMessage({ message: data.data.message, error: false });
          },
          onError: (error) => {
            createToastMessage({ message: error, error: true });
          },
        }
      );
    }
  };

  const backToOrders = () => {
    history.push('/orders');
  };

  return (
    <React.Fragment>
      <Card className={classes.root}>
        <div className={classes.details}>
          <div className={classes.call}>
            <Button
              color="default"
              startIcon={<ArrowBackIcon />}
              onClick={() => {
                setChannel(null);
                setAssigned(false);
                setCurrentOrderID(null);
                backToOrders();
              }}
            >
              Back to Orders
            </Button>
          </div>
        </div>
        <div className={classes.call}>
          <Button
            size="large"
            variant="outlined"
            className={classes.button}
            startIcon={<ScheduleIcon color="primary" />}
            onClick={handleOpenReschedule}
          >
            Reschedule
          </Button>
          <Button
            startIcon={<StorefrontIcon color="primary" />}
            onClick={handleOpenChangeStore}
            size="large"
            variant="outlined"
            className={classes.button}
          >
            Change store
          </Button>
          <Button
            startIcon={<PersonOutlineIcon color="inherit" />}
            onClick={handleAssignOrder}
            size="large"
            variant="outlined"
            className={`${classes.button} ${classes.colorGreen}`}
          >
            Assign to me
          </Button>
          <Button
            startIcon={<PauseIcon color="inherit" />}
            onClick={handleHoldOrder}
            size="large"
            variant="outlined"
            className={`${classes.button} ${classes.colorOrange}`}
          >
            Hold order
          </Button>
          <Button
            startIcon={<RotateLeftIcon color="inherit" />}
            onClick={handleResetOrder}
            size="large"
            variant="outlined"
            className={`${classes.button} ${classes.colorRed}`}
          >
            Reset
          </Button>
          <Button
            startIcon={<LocalShippingIcon color="primary" />}
            onClick={handlePicup}
            size="large"
            variant="outlined"
            className={classes.button}
          >
            Post to Picup
          </Button>
        </div>
      </Card>
      {openReschedule && (
        <RescheduleOrder orderID={orderID} open={openReschedule} closeReschedule={handleCloseReschedule} />
      )}
      {openChangeStore && (
        <ChangeStore orderID={orderID} open={openChangeStore} closeChangeStore={handleCloseChangeStore} />
      )}
    </React.Fragment>
  );
};

export default OrderActionButtons;
