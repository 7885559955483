import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { axiosInstance } from '../utils/utils';
import { useQuery } from 'react-query';
import Loading from '../components/Loader';
import { useHistory } from 'react-router-dom';
import * as store from 'store';

const useStyles = makeStyles({
  login: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    display: 'flex',
    position: 'absolute',
    top: '30%;',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 250,
    objectFit: 'fill',
  },
});

const spotlightOnline = (accessToken) => {
  return axiosInstance.post(
    '/v1/spotlight/online',
    {},
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );
};

const Login = () => {
  const { isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  const pushToOrders = () => {
    if (store.get('spotlightAccessToken') !== null) {
      history.push('/orders');
    }
  };

  const classes = useStyles();
  const { data: accessToken } = useQuery(['accessToken'], getAccessTokenSilently, { enabled: !!isAuthenticated });

  const { isLoading } = useQuery(['spotlight', accessToken], () => spotlightOnline(accessToken), {
    // The query will not execute until the accessToken exists
    enabled: !!(accessToken && isAuthenticated),
    retry: false,
    onSuccess: () => {
      const expiresAt = JSON.stringify(86400 * 1000 + new Date().getTime());
      store.set('spotlightAccessToken', accessToken);
      store.set('expiresAt', expiresAt);
      pushToOrders();
    },
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className={classes.login}>
      <Card className={classes.root}>
        <CardMedia
          component="img"
          className={classes.cover}
          image="https://onecart.s3-eu-west-1.amazonaws.com/website/OneCart_logo.svg"
          title="Sign in"
        />
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography variant="subtitle1" color="textSecondary">
              Sign in to your account
            </Typography>
            <Button onClick={() => loginWithRedirect()} variant="contained" color="primary">
              Login
            </Button>
          </CardContent>
        </div>
      </Card>
    </div>
  );
};

export default Login;
