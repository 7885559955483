import React from 'react';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { ChatProvider } from './contexts/ChatContext';
import { Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Shadows } from '@material-ui/core/styles/shadows';
import { createBrowserHistory } from 'history';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import Orders from './pages/Orders';
import Order from './pages/Order';
import Login from './pages/Login';
import ShoppingList from './pages/ShoppingList';
import NotFound from './pages/NotFound';
import ProtectedRoute from './auth/ProtectedRoute';
const theme = createMuiTheme({
  shadows: Array(25).fill('none') as Shadows,
  palette: {
    primary: {
      main: '#57A946',
    },
  },
  typography: {
    fontFamily: ['Rubik'].join(','),
    button: {
      textTransform: 'none',
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
  },
});

const queryClient = new QueryClient();

const App = () => {
  const { error } = useAuth0();
  const history = createBrowserHistory();
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Auth0Provider
          domain="shopper.auth0.com"
          clientId="oo3RKUhzEdJ7kNOJfmkKSVn4XG1P41AL"
          redirectUri={window.location.origin}
          scope="openid email profile"
        >
          <ChatProvider>
            <Router history={history}>
              <Switch>
                <Route exact path="/" component={Login} />
                <ProtectedRoute exact path="/orders" component={Orders} />
                <ProtectedRoute exact path="/orders/:orderID" component={Order} />
                <ProtectedRoute exact path="/orders/:orderID/:vendor" component={ShoppingList} />
                <Route component={NotFound} />
              </Switch>
            </Router>
          </ChatProvider>
        </Auth0Provider>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
  );
};

export default App;
