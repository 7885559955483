import React, { FunctionComponent, ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 150,
    marginTop: theme.spacing(2),
  },
  label: {
    backgroundColor: 'white',
  },
  menuPaper: {
    maxHeight: 200,
  },
}));

interface StatusSelectProps {
  AppState: any;
}

const StatusSelect: FunctionComponent<StatusSelectProps> = (props) => {
  const { AppState } = props;
  const [state, dispatch] = AppState();
  const { selectedStatus: status } = state;

  const handleStatusChange = (event: ChangeEvent<{ value: unknown }>): void => {
    dispatch({ type: 'SET_SELECTED_STATUS', payload: event.target.value as number[] });
  };

  const classes = useStyles();
  const statuses = [
    { name: 'Active', value: 3 },
    { name: 'Pending', value: 2 },
    { name: 'Running late', value: 4 },
    { name: 'Completed', value: 1 },
    { name: 'Cancelled', value: 5 },
  ];

  const statusNames = (selected: unknown) => {
    const selectedNames = (selected as string[]).map((sel) => {
      const status = statuses.find((stat) => stat.value === Number(sel));
      return status?.name;
    });
    return selectedNames.join(', ');
  };
  return (
    <div>
      <FormControl style={{ maxWidth: 120 }} variant="outlined" className={classes.formControl}>
        <InputLabel className={classes.label} id="status-label">
          Choose Status
        </InputLabel>
        <Select
          labelId="statusselect-label"
          id="status-select"
          multiple
          value={status}
          onChange={handleStatusChange}
          input={<OutlinedInput />}
          renderValue={(selected) => statusNames(selected)}
          MenuProps={{
            classes: { paper: classes.menuPaper },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          {statuses.map((stat) => {
            return (
              <MenuItem key={stat.name} value={stat.value}>
                <Checkbox checked={status.indexOf(stat.value) > -1} />
                <ListItemText primary={stat.name} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default StatusSelect;
