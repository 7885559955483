import React from 'react';

const StatusIcon = ({ imageUrl, display }: {imageUrl: string, display: boolean}) => {
  if (!display) return null;
  return (
    <div style={{ float: 'left' }}>
      <img alt="Status" src={imageUrl} style={{ paddingRight: '2px' }} />
    </div>
  );
};

export default StatusIcon;
