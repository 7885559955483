import React, { Fragment, FunctionComponent, useContext } from 'react';
import { ChatContext } from '../../contexts/ChatContext';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import InboxModal from './InboxModal';
import Chip from '@material-ui/core/Chip';
import { Chat, ChannelList, LoadingIndicator } from 'stream-chat-react';
import MyChannelPreview from './MyChannelPreview';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '372px',
      maxWidth: '60ch',
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: 'inline',
    },
    unreadCount: {
      width: '372px',
      paddingLeft: '3px',
      paddingBottom: '15px',
      marginBottom: 5,
      boxShadow: '0 4px 6px -6px rgba(0, 0, 0, .15)',
    },
  })
);

interface ChangeInboxProps {
  open: boolean;
  closeChatInbox: () => void;
  tableInstance: any;
  history: any;
}

const InAppChat: FunctionComponent<ChangeInboxProps> = (props) => {
  const classes = useStyles();
  const { chatClient, chatfilter, setChannel, unread, orders } = useContext(ChatContext);
  const { open, closeChatInbox, history } = props;

  const options = {
    watch: true,
    limit: 4,
  };

  if (!chatClient) {
    return <LoadingIndicator />;
  }

  return (
    <Fragment>
      <InboxModal title="Chat Inbox" openModal={open} closeModal={closeChatInbox}>
        <>
          {!chatfilter ? (
            <h4 style={{ textAlign: 'center', width: '100px', border: '1px solid black' }}>
              There are no channels available yet!
            </h4>
          ) : (
            <>
              <div className={classes.unreadCount}>
                Unread messages:
                <span>
                  {' '}
                  <Chip label={unread} style={{ background: '#f50057', color: 'white' }} />
                </span>
              </div>
              <Chat client={chatClient} theme={classes.root}>
                <ChannelList
                  filters={chatfilter}
                  options={options}
                  Preview={(props) => (
                    <MyChannelPreview {...props} orders={orders} history={history} setChannel={setChannel} />
                  )}
                />
              </Chat>
            </>
          )}
        </>
      </InboxModal>
    </Fragment>
  );
};

export default InAppChat;
