import React, { FunctionComponent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { OrderSummary } from '../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      height: '100%',
      background: 'white',
      width: '100%',
      borderRadius: theme.spacing(2),
      color: theme.palette.text.secondary,
      '& svg': {
        margin: theme.spacing(1.5),
      },
      '& hr': {
        margin: theme.spacing(0, 0.5),
      },
    },
    card: {
      height: '100%',
      width: '30%'
    },

    title: {
      fontSize: 50,
      textAlign: 'center',
    },
    pos: {
      textAlign: 'center',
    },
  })
);
interface OrdersSummaryProps {
  ordersSummary: OrderSummary;
  tableInstance: any;
}

const OrdersSummary: FunctionComponent<OrdersSummaryProps> = (props) => {
  const classes = useStyles();
  const { ordersSummary } = props;

  return (
    <Grid container alignItems="center" className={classes.root}>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h2" style={{ color: '#11B0F2' }} className={classes.title} gutterBottom>
            {ordersSummary.totalOrders}
          </Typography>
          <Typography className={classes.pos}>Total Orders</Typography>
        </CardContent>
      </Card>
      <Divider orientation="vertical" style={{ height: '50px' }} />
      <Card style={{ color: '' }} className={classes.card}>
        <CardContent>
          <Typography variant="h2" style={{ color: '#85CC66' }} className={classes.title} gutterBottom>
            {ordersSummary.totalCompleted}
          </Typography>
          <Typography className={classes.pos}>Completed Orders</Typography>
        </CardContent>
      </Card>
      <Divider orientation="vertical" style={{ height: '50px' }} />
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h2" style={{ color: '#EEAA23' }} className={classes.title} gutterBottom>
            {ordersSummary.inProgress}
          </Typography>
          <Typography className={classes.pos}>In Progress</Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default OrdersSummary;
