import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import { useParams, useLocation } from 'react-router-dom';
import { parseImage } from '../utils/utils';
import Box from '@material-ui/core/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
interface ParamTypes {
  orderID: string;
}
interface IState {
  shopper: string;
  isCompleted: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(2),
  },
  appBar: {
    backgroundColor: 'white',
    color: '#44444F',
    borderRadius: theme.spacing(2),
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  list: {
    width: '100%',
  },
  productList: {
    width: '100%',
  },
  productListItem: {
    marginRight: theme.spacing(10),
  },

  itemText: {
    marginRight: theme.spacing(3),
    maxWidth: '30%',
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  productImage: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  listAvatar: {
    maxWidth: '10%',
  },
}));

const VendorShoppingList = () => {
  const history = useHistory();
  const classes = useStyles();
  let { orderID } = useParams<ParamTypes>();
  const location = useLocation();
  const vendor = (location.state as any)?.vendor;
  const isCompleted = (location.state as IState).isCompleted;

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const backToOrder = () => {
    history.push(`/orders/${orderID}`, { isCompleted });
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static">
        <Toolbar className={classes.toolbar}>
          <List className={classes.list}>
            <ListItem>
              <IconButton onClick={() => backToOrder()} edge="start" color="inherit" aria-label="open drawer">
                <ArrowBackIosIcon />
              </IconButton>
              <Typography className={classes.title} variant="h6">
                Shopping List: {orderID}
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar src={parseImage(vendor.vendor)}></Avatar>
              </ListItemAvatar>
              <ListItemText primary={vendor.vendor} />
            </ListItem>
          </List>
        </Toolbar>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Vendor shopping list "
          indicatorColor="primary"
          variant="fullWidth"
        >
          <Tab label="Shopping List" {...a11yProps(0)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Box>
          <List className={classes.productList}>
            {vendor.products.map((product: any) => {
              return (
                <ListItem className={classes.productListItem}>
                  <ListItemAvatar className={classes.listAvatar}>
                    <Avatar className={classes.productImage} src={product.imageURL}></Avatar>
                  </ListItemAvatar>
                  <ListItemText className={classes.itemText} secondary="Product" primary={product.name} />
                  <ListItemText className={classes.itemText} secondary="Quantity" primary={product.orderQuantity} />
                  <ListItemText className={classes.itemText} secondary="Price" primary={product.price} />
                </ListItem>
              );
            })}
          </List>
        </Box>
      </TabPanel>
    </div>
  );
};

export default VendorShoppingList;
