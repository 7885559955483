import React, { FunctionComponent } from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';

interface DateFilterProps {
  setStartDate: (date: Date | null) => void;
  setEndDate: (date: Date | null) => void;
  startDate: string;
  endDate: string;
}

const useStyles = makeStyles((theme) => ({
  datepicker: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    width: '30%',
  },
}));
const DateFilter: FunctionComponent<DateFilterProps> = (props) => {
  const classes = useStyles();
  const { setStartDate, setEndDate, startDate, endDate } = props;
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        inputVariant="outlined"
        variant="inline"
        format="MM/dd/yyyy"
        id="startDate"
        label="From"
        className={classes.datepicker}
        value={startDate}
        onChange={setStartDate}
        fullWidth
      />
      <DatePicker
        inputVariant="outlined"
        variant="inline"
        format="MM/dd/yyyy"
        id="endDate"
        label="To"
        className={classes.datepicker}
        value={endDate}
        onChange={setEndDate}
      />
    </MuiPickersUtilsProvider>
  );
};

// DateFilter.propTypes = {};

export default DateFilter;
